@font-face {
    font-family: "Onsite Standard";
    font-weight: 400;
    src:
      local("Onsite Standard"),
      url('/assets/fonts/OnsiteStandard-Regular.woff2') format("woff2");
}

@font-face {
    font-family: "Onsite Standard";
    font-weight: 300;
    src:
      local("Onsite Standard"),
      url('/assets/fonts/OnsiteStandard-Light.woff2') format("woff2");
}

@font-face {
    font-family: "Onsite Standard";
    font-weight: 500;
    src:
      local("Onsite Standard"),
      url('/assets/fonts/OnsiteStandard-Medium.woff2') format("woff2");
}

@font-face {
    font-family: "Onsite Standard";
    font-weight: 600;
    src:
      local("Onsite Standard"),
      url('/assets/fonts/OnsiteStandard-Bold.woff2') format("woff2");
}

@font-face {
    font-family: "Riccione";
    font-weight: 600;
    src:
      url('/assets/fonts/RiccioneSerial-Bold.otf') format("opentype");
}
@font-face {
    font-family: "Riccione";
    font-weight: 300;
    src:
      url('/assets/fonts/RiccioneSerial-Light.otf') format("opentype");
}
@font-face {
    font-family: "Riccione";
    font-weight: 400;
    src:
      url('/assets/fonts/RiccioneSerial.otf') format("opentype");
}


:root{
    // --margin:50px;
    --margin:40px;
    --gradient: linear-gradient(180deg, #FFE708 0%, #FFD600 100%);
    --gap: 40px;
    --serif: Riccione, 'Times New Roman', serif;
    --sans-serif: Onsite Standard, sans-serif;
    --yellow: #FFEB0A;
    --yellow: #FCF70D;
    --grey:#ECECE0;
    --col-3-width: calc((100vw - var(--margin)*2)/3 - var(--gap)*2/3);
    --col-2-width: calc((100vw - var(--margin)*2 - var(--gap))/2);
}

@media screen and (max-width: 700px){
    :root{
        --margin:30px;
        --gap: 25px;
    }
}

::selection {
    background: hsla(59, 98%, 52%, 0.5);
    // mix-blend-mode: multiply;
    // opacity:0.5;
  }
  ::-moz-selection {
    background: hsla(59, 98%, 52%, 0.5);
    // mix-blend-mode: multiply;
    // opacity:0.5;    
  }

html{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

body{
    font-family: var(--sans-serif);
    letter-spacing: -0.02em;
    margin:0;
    // background: #efefef;
}
section{
    padding:var(--margin);
}
#canvasContainer {
    canvas {
        height: 160px;
        width: 160px;
        position:fixed;
        top:-10px;
        left:-1000px;
        z-index:100;
        transition: left 800ms ease-in-out;
    }
    canvas.visible{
        left: -10px;
    }
}
.invert{
    background: black;
    color: white;
    p{
        color: white;
    }
}
.cta:hover{
    background: var(--yellow);
    box-shadow: 0px 0px 5px 0px rgba(255, 221, 0, 0.5) inset;
    color: black;
}
nav{
    display:flex;
    justify-content: space-between;
    padding:var(--margin);
    width: calc(100% - var(--margin)*2);
    .cta{
        // background: #ededed;
        // font-weight:500;
        font-weight: 400;
        font-size: 0.85rem;
    }
    .logo{
        height: 30px;
        display:flex;
        gap:0;
        width: 84px;
        svg{
            height: 100%;
            path{
                fill: white;
                fill: black;
            }
            
        }
    }
    @media screen and (max-width: 700px){
        .logo{
            transform:translateY(-3px);
        }
    }
}
a{
    text-decoration: none;
    color: black;
    font-weight:600;
}

h1{
    // font-size: 100px;
    // font-size: 80px;
    // font-size: 60px;
    // font-size:10vw;
    font-size:11vw;
    letter-spacing: -0.05em;
    font-weight:500;
    line-height: 1;
    margin-bottom: 0.25em;
}

h2{
    font-weight: 300;
    font-size: 3rem;
    line-height: 110%;
    letter-spacing: -0.04em;
    color: #1B1B1B;
}

h3{
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: -0.03em;
}
h4{
    font-size: 1.5rem;
    margin:0;
}
p, ul {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.28;
    color: #1B1B1B;
    max-width: 600px;
}
ul {
    padding-left: 20px;
    max-width: calc(600px - 20px);
}

hr {
    max-width: 600px;
    margin-left: 0;
}
.small{
    font-size: 0.85rem;
    letter-spacing: -0.02em;
}
.yellow{
    color: var(--yellow);
}
.btn{
    // background: #222222;
    // color: white;
    background: white;
    color: black;
    border: 1px solid #dbdac7;
    border-radius: 5px;
    padding: 10px 15px;
    font-weight:400;
}

footer{
    background: black;
    color: white;
    // height: 40px;
    font-weight: 400;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    > div{
        display: flex;
        gap: 5px;

    }
    a{
        color: var(--grey);
        font-weight: 400;
        padding: 5px 9px;
        border-radius: 3px;
        font-size: 12px;
        letter-spacing: -0.01em;
        display: block;
    }
    a:hover{
        background: #222222;
    }
}
@media screen and (max-width: 1050px){
    p{
        font-size: 1.15rem;
    }
}
@media screen and (max-width: 950px){
   :root{
    --gap: 30px;
   }
   footer{
    flex-wrap: wrap;
    gap: 5px;
    > *{
        flex-wrap: wrap;
    }
   }
}
@media screen and (max-width: 700px){
    h2{
        font-size: 2.75rem;
    }
    h3{
        font-size:2rem;
    }
    p{
        font-size: 1rem;
    }
}
@media screen and (max-width: 600px){
    footer{
        display:block;
        > div{
            display:block;
        }
    }
}
#open_privacy_preferences_element{
    cursor: pointer;
}